import 'select2';
import { initLocale } from 'packs/i18n';

const translation = initLocale($("body").data('locale'))
const MIN_BULK_CARGO_WEIGHT = 500

// ================== HTML ============================
const createBulkElement = () => {
  return `
    <div class="bulk-popover-body">
      <div class="bulk-weight__wrapper">
        <label class="bulk-weight__label" for="bulk-weight">Gross Weight, MT</label>
        <input min="${MIN_BULK_CARGO_WEIGHT}" max="200000" type="number" class="component-search__input-wrapper" id="bulk-weight" value="${bulkState.weight}" step="500">
        <div class="component-search__validation-error weight-error bulk-error">Weight value should be set more than ${MIN_BULK_CARGO_WEIGHT}</div>
      </div>
      <div class="bulk-commodity__wrapper">
        <select id="bulk-commodity"></select>
        <div class="component-search__validation-error commodity-error bulk-error">Commodity type should be selected</div>
      </div>
    </div>
    <div class="popover-done-button-wrapper">
      <button class="btn btn-link bulk__done">
        ${translation['done']}
      </button>
    </div>
  `;
}

// ==================== ACTIONS ============================
function handleDone(callback) {
  $(".weight-error").css("display", parseInt(Number(bulkState.weight || 0)) < MIN_BULK_CARGO_WEIGHT ? "block" : "none")
  $(".commodity-error").css("display", !bulkState.commodity ? "block" : "none")

  if(parseInt(Number(bulkState.weight || 0)) >= MIN_BULK_CARGO_WEIGHT && bulkState.commodity) {
    let text = `${bulkState.weight} tons; ${bulkState.commodity}`
    let value = `${bulkState.weight};${bulkState.commodity}`
  
    callback(text, value)
  }
}

// ==================== STATE ============================
const bulkState = {}
let resetState = true;

function setBulkState(state) {
  if (resetState) {
    bulkState.weight = state.weight
    bulkState.commodity = state.commodity
  }

  resetState = true
}

export function renderBulk({ bulk_commodities, signalBulkStateChanged, signalBulkDoneClicked, state }) {
  setBulkState(state)

  const bulkElement = $(createBulkElement())

  bulkElement.find("#bulk-weight").on('change', function () {
    bulkState.weight = $(this).val()
    resetState = false
    signalBulkStateChanged()
  })

  bulkElement.find("#bulk-commodity").select2({
    data: bulk_commodities,
    placeholder: 'Commodity Type',
    allowClear: true,
    width: '100%',
  })
    .on('change', function () {
      const reRender = $(this).val() && bulkState.commodity !== $(this).val()
      bulkState.commodity = $(this).val()
      resetState = false

      if (reRender) signalBulkStateChanged()
    })
    .val(bulk_commodities.filter((c) => c.toLowerCase() === bulkState.commodity?.toLowerCase())[0])
    .trigger('change')

  // onClick Done
  bulkElement
    .find('button.bulk__done')
    .on('click', () => { handleDone(signalBulkDoneClicked) });

  return bulkElement
}

export function forceTriggerBulkDone({signalBulkDoneClicked}){
  handleDone(signalBulkDoneClicked)
}